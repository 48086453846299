<template>
  <div class="home" ref="home" id="home">
    <!-- banner -->
    <div class="home_section_top bgc2">
      <div class="home_section_top_container space-between-row">
        <div class="col">
          <div class="home_section_top_title fc1">{{ this.$t("zhongkui.banner.title") }}</div>
          <div class="home_section_top_subtitle fc2">{{ this.$t("zhongkui.banner.subTitle") }}</div>
          <div class="home_section_top_btn pointer" @click="showUsQrCode">
            {{ this.$t("zhongkui.banner.contactUs") }}
          </div>
        </div>
        <div class="col">
          <img class="home_section_top_img" :src="require(`@/assets/img/prod-zhongkui.png`)"/>
        </div>
      </div>
    </div>

    <!-- 核心优势-->
    <div class="home_section home_section_advantages bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("zhongkui.advantages.title") }}</label>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/zhongkui-youshi-1.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.advantages.info1.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.advantages.info1.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/zhongkui-youshi-2.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.advantages.info2.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.advantages.info2.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/zhongkui-youshi-3.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.advantages.info3.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.advantages.info3.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/zhongkui-youshi-4.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.advantages.info4.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.advantages.info4.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/zhongkui-youshi-5.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.advantages.info5.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.advantages.info5.subTitle") }}</label>
            </div>
            <div class="home_section_sub col bgc3 hover-border hover-no-bg">
              <img :src="require(`@/assets/img/zhongkui-youshi-6.png`)"/>
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.advantages.info6.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.advantages.info6.subTitle") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品功能-->
    <div class="home_section home_section_product">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("zhongkui.product.title") }}</label>
          </div>
          <div class="space-between-row w100">
            <div class="col home_section_left">
              <div class="row home_section_menu border1 hover-bgc2 pointer" 
              :class="{'bgc2': hoverIndex == index,'is-en': lang=='en'}"
              v-for="(item, index) in productList" :key="item.title"
              @mouseover="stopAutoPlay(index)" @mouseleave="startAutoPlay">
                <img :src="require(`@/assets/img/zhongkui-icon-${index+1}.svg`)" class="svg-fc1"/>
                <div class="fc1">{{ item.title }}</div>
              </div>
            </div>
            <div class="col home_section_right bgc2" v-for="(item, index) in productList" :key="item.subTitle" v-show="hoverIndex == index">
              <img :src="item.img"/>
              <div class="fc2">
                {{ item.subTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section7 case-->
    <div class="home_section home_section_case bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section7.title") }}</label>
          </div>
          <div class="home_section_sub_list">
            <div class="home_section_sub hover-jump hover-bgc1 border1 col" v-for="item in newsList" :key="item.url" @click="goBlank(item.url)">
              <div class="news_title fc1">{{ item.title }}</div>
              <div class="news_intro fc2">{{ item.detail }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 为您提供-->
    <div class="home_section home_section_provide">
      <div class="home_section_container col">
        <div class="home_section_text w100">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("zhongkui.provide.title") }}</label>
          </div>
        </div>
        <div class="space-between-row w100">
          <div class="home_section_left bgc3 col">
            <label class="home_section_title2 fc1">{{ this.$t("zhongkui.provide.info1.title") }}</label>
            <label class="home_section_title2 fc2">{{ this.$t("zhongkui.provide.info1.subTitle") }}</label>
            <div class="bit-button bgc2 fc1 pointer" @click="showUsQrCode">{{ this.$t("zhongkui.provide.info1.btn") }}</div>
            <img :src="require(`@/assets/img/zhongkui-platform.png`)" />
          </div>
          <div class="home_section_right col">
            <div class="home_section_box col border1 hover-bgc1">
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.provide.info2.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.provide.info2.subTitle") }}</label>
            </div>
            <div class="home_section_box col border1 hover-bgc1">
              <label class="home_section_title2 fc1">{{ this.$t("zhongkui.provide.info3.title") }}</label>
              <label class="home_section_title2 fc2">{{ this.$t("zhongkui.provide.info3.subTitle") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section8 social-->
    <div class="home_section home_section_social bc1">
      <div class="home_section_container space-between-row">
        <div class="home_section_text">
          <div class="home_section_title1 space-between-row fc1">
            <label>{{ this.$t("home.section8.title") }}</label>
          </div>
          <div class="home_section_title2 space-between-row fc2" style="margin-top: 16px;">
            <label>{{ this.$t("home.section8.subTitle") }}</label>
          </div>
          <div class="row">
            <img class="hover-jump" @click="goBlank(x_url)" :src="require(`@/assets/img/icon-x.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(redbook_url)" :src="require(`@/assets/img/icon-redbook.png`)" width="48" height="48"/>
            <img class="hover-jump" @click="goBlank(tiktok_url)" :src="require(`@/assets/img/icon-tiktok.png`)" width="48" height="48"/>
            <el-popover
              :popper-class="'qrcode-popover shadow-'+theme"
              placement="bottom-start"
              width="240"
              :visible-arrow="false"
              trigger="click">
              <img slot="reference" class="hover-jump" :src="require(`@/assets/img/icon-wechat.png`)" width="48" height="48"/>
              <img class="code-item-img" :src="require(`@/assets/img/qrcode-weixin-${theme}.png`)" width="248" height="248"/>
            </el-popover>
          </div>
        </div>
        <div>
          <img :src="require(`@/assets/img/icon-news.png`)" width="380" height="230"/>
        </div>
      </div>
    </div>

    <BitBottom/>

  </div>
</template>

<script>
import { newsList } from "@/api/case";
import BitBottom from "@/components/BitBottom.vue";

export default {
  name: "home",
  components: { BitBottom },
  mounted() {
    this.getArtList();
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  },
  computed:{
    theme: function () {
      let t = this.$store.state.theme
      if(t==null){
        t = localStorage.getItem("theme")
      }
      if(t == null) t = 'dark'
      return t
    },
    lang: function () {
      return this.$i18n.locale
    },
    productList: function () {
      return this.$t("zhongkui.product.list")
    }
  },
  data() {
    return{
      timer: null,
      hoverIndex: 0,
      newsList: [],
      showRQCode1: false,
      showRQCode2: false,
      x_url: "https://x.com/BitJungle_team",
      tiktok_url: "https://www.douyin.com/user/MS4wLjABAAAAjmzdoKmLOD_yxzwFID-IiIHnjI3Nmm5M2bDuzIAkhj8cBl1mPmStgSPmW9fHpnva",
      redbook_url: "https://www.xiaohongshu.com/user/profile/6698756b000000000f03469e"
    }
  },
  methods: {
    startAutoPlay() {
      this.stopAutoPlay(); // 确保不会创建多个定时器
      this.timer = setInterval(() => {
        this.hoverIndex = (this.hoverIndex + 1) % this.productList.length;
      }, 5000); // 每 5s 轮播
    },
    stopAutoPlay(index = null) {
      clearInterval(this.timer);
      if (index !== null) {
        this.hoverIndex = index; // 切换到手动 hover 的项
      }
    },
    showRQCode(index){
      if(index==1)
        this.showRQCode1 = !this.showRQCode1
      else(index==2)
        this.showRQCode2 = !this.showRQCode2
    },
    showUsQrCode(){
      this.$bus.$emit("showUsQrCode");
    },
    goBlank(url){
      window.open(url)
    },
    showMsg(msg){
      this.$message({
        showClose: false,
        customClass: 'bit-msg bit-msg-'+this.theme,
        message: msg
      });
    },
    router(path){
      if(!path || path==''){
        this.showMsg(this.$t("undoMsg"))
        return
      }
      this.$router.push(path)
    },
    getArtList(){
      newsList().then(res => {
        this.newsList = res.data.data.list
      })
    },
    contactUs() {
      this.$emit("scrollToBottom");
    },
  },
};
</script>
<style lang="scss" scoped>
.dark{
  .home_section_social {
    background-image: url("../../assets/img/banner-news-dark.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-dark.png");
  }
}
.light{
  .home_section_social {
    background-image: url("../../assets/img/banner-news-light.png");
  }
  .code-item{
    background-image: url("../../assets/img/banner-qrcode-light.png");
  }
}
.home {
  box-sizing: border-box;
  flex: 1;
  .home_section_top {
    height: 480px;
    display: flex;
    justify-content: center;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    .home_section_top_container {
      width: 1200px;
      padding: 120px 0;
      position: relative;
      box-sizing: border-box;
      .col{
        justify-content: center;
      }
    }
    .home_section_top_title {
      height: 64px;
      line-height: 64px;
      font-size: 56px;
      font-weight: 500;
    }

    .home_section_top_btn {
      position: relative;
      margin-top: 80px;
      width: 144px;
      height: 48px;
      line-height: 48px;
      box-sizing: border-box;
      background: linear-gradient(109deg, #1C5BF9 1%, #1C5BF9 11%, #13B9CB 48%, #32E355 85%);
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      .code-item{
        position: absolute;
        top: 60px;
      }
    }

    .home_section_top_subtitle {
      margin-top: 16px;
      font-size: 24px;
      width: 584px;
    }

    .home_section_top_describe {
      font-size: 23px;
      margin-top: 5px;
      width: 530px;
    }

    .home_section_top_img{
      width: 480px;
      height: 340px;
      position: absolute;
      right: 0;
    }
  }

  .home_section_advantages {
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        gap: 24px !important;
        .home_section_sub{
          width: 384px !important;
          min-height: 228px !important;
          height: auto !important;
          padding: 40px !important;
          box-sizing: border-box;
          align-items: flex-start !important;
          justify-content: flex-start !important;
          position: relative;
          img{
            width: 72px;
            height: 72px;
          }
          .fc1{
            font-size: 20px;
            height: 28px;
            line-height: 28px;
            margin-top: 16px;
            margin-bottom: 8px;
          }
          .fc2{
            font-size: 14px;
            height: auto !important;
            line-height: 20px;
            text-align: left;
          }
        }
      }
    }
  }

  .home_section_product {
    .home_section_container {
      padding: 88px 0px;
      .space_between_row {
        gap: 16px !important;
      }
      .home_section_left{
        margin-top: 40px;
        gap: 16px !important;
        .home_section_menu{
          border-radius: 4px;
          overflow: hidden;
          box-sizing: border-box;
          width: 448px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: left;
          padding-left: 166px;
          img{
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
          div{
            font-size: 20px;
            font-weight: 500;
          }
        }
        .is-en{
          padding-left: 120px !important;
        }
      }
      .home_section_right{
        width: 722px;
        height: 488px;
        padding: 44px 32px;
        box-sizing: border-box;
        margin-top: 40px;
        gap: 16px !important;
        img{
          width: 660px;
          height: 364px;
        }
        .fc2{
          font-size: 14px;
          font-weight: 400;
          height: 20px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }

  .home_section_provide {
    .home_section_container {
      padding: 88px 0px;
      .home_section_text{
        margin-bottom: 40px;
      }
      .space-between-row {
        display: flex;
        justify-content: space-between;
        gap: 16px !important;
      }
      .home_section_left{
        width: 760px;
        height: 344px;
        padding: 60px;
        position: relative;
        border-radius: 4px;
        img{
          width: 200px;
          height: 200px;
          position: absolute;
          top: 72px;
          right: 86px;
        }
        .bit-button{
          width: 112px;
          height: 40px !important;
          box-sizing: border-box;
          line-height: 40px !important;
          text-align: center;
          border-radius: 4px;
          position:absolute;
          left: 60px;
          bottom: 60px;
          margin: 0 !important;
          font-size: 16px !important;
          border: 1px solid transparent;
          font-weight: 400 !important;
          background-clip: padding-box,border-box;
          background-origin: padding-box,border-box;
        }
        .fc1{
          font-size: 24px;
          font-weight: 500;
          height: 34px;
          line-height: 34px;
          margin-bottom: 12px;
        }
        .fc2{
          font-size: 16px;
          font-weight: 400;
          height: 28px;
          line-height: 28px;
        }
      }
      .home_section_right{
        gap: 16px !important;
        .home_section_box{
          width: 424px;
          height: 164px;
          border-radius: 4px;
          padding: 32px;
          .fc1{
            font-size: 20px;
            font-weight: 500;
            height: 32px;
            line-height: 32px;
            margin-bottom: 12px;
          }
          .fc2{
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            height: 56px;
          }
        }
      }
    }
  }

  .home_section_achievements{
    height: 172px;
    background: linear-gradient(96deg, #4C1FD3 2%, #2E62E2 26%, #31C79D 65%, #54EA72 97%);
    color: #FFFFFF;
    .achievements-item{
      gap: 4px;
    }
    .title{
      font-size: 64px;
      height: 64px;
      line-height: 64px;
      font-weight: 600;
      margin-right: 2px;
    }
    .unit{
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 600;
    }
    .subTitle{
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      height: 24px;
    }
  }

  .home_section_case {
    height: 484px !important;
    .home_section_container {
      padding: 88px 0px;
      .home_section_sub_list{
        flex-wrap: wrap;
        width: 100%;
        gap: 24px !important;
        .home_section_sub{
          width: 384px !important;
          height: 220px !important;
          padding: 32px !important;
          .news_title{
            max-height: 64px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
          .news_intro{
            font-size: 16px;
            font-weight: normal;
            line-height: 28px;
            max-height: 84px;
            text-align: justify;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 限制为两行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }
      }
    }
  }

  .home_section_social{
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 390px;
    .home_section_container{
      padding: 88px 0px;
    }
    .home_section_text{
      height: 230px;
      .home_section_title2{
        margin-top: 16px;
        font-weight: 400 !important;
      }
      .row{
        margin-top: 60px;
        gap: 24px;
        position: relative;
        img{
          cursor: pointer;
        }
        .code-item{
          position: absolute;
          right: 100px;
          top: 60px;
        }
      }
    }
  }
}
</style>
